
import {computed, defineComponent, reactive, toRefs} from "vue";
import {useStore} from "vuex";
import {key} from "@/store";
import useProduct from "@/composables/useProduct";

export default defineComponent({
  name: 'FavoriteProduct',
  setup() {
    const store = useStore(key)
    store.dispatch('product/fetchFavoriteProducts')
    const { removeFromFavorites, gotoDetailPage} = useProduct()
    const state = reactive({
    })

    const favoriteProducts = computed(() => store.getters['product/getFavoriteProducts'])

    return {
      removeFromFavorites,
      gotoDetailPage,
      favoriteProducts,
      ...toRefs(state)
    }
  },

});
